export const TYPE_MAP = {
  ALL: {
    title: '全部'
  },
  //业绩
  DEALER_TARGET: {
    url: '/dataCenter/bgi/dealerBGI',
    title: '经销商指标'
  },
  DEALER_TARGET_CA: {
    url: '/dataCenter/bgi/dealerBGI-CA',
    title: '经销商指标-CA'
  },
  DEALER_TARGET_GPO: {
    url: '/dataCenter/bgi/dealerBGI-GPO',
    title: '经销商指标-GPO'
  },
  SAP_SCORECARD: {
    url: '/dataCenter/performance/sap',
    title: 'SAP Scorecard'
  },
  RCR_SCORECARD: {
    url: '/dataCenter/performance/rcr',
    title: 'RCR Scorecard'
  },

  //绩效
  KPI_POLICY: {
    url: '/dataCenter/cib/kpiPolicy',
    title: 'KPI绩效政策'
  },
  KPI_RESULT: {
    url: '/dataCenter/kpi/kpiScore',
    title: 'KPI绩效考核结果'
  },

  //激励
  INCENTIVE_POLICY: {
    url: '/dataCenter/cib/policy',
    title: '激励政策'
  },
  CIB_FRAMEWORK: {
    url: '/dataCenter/cib/framework',
    title: 'CIB框架'
  },
  CIB_WARNING: {
    url: '/dataCenter/cib/warning',
    title: 'CIB预警'
  },
  CIB_CONFIRMATION: {
    url: '/dataCenter/cib/confirmation/index',
    title: 'CIB确认函'
  },

  //渠道，盘点和承知一个页面
  CHANNEL_DATA_KNOW: {
    url: '/dataCenter/channel-data/inventory',
    title: '渠道数据承知'
  },
  CHANNEL_DATA_CHECK: {
    url: '/dataCenter/channel-data/inventory',
    title: '渠道盘点'
  },
  CHANNEL_DATA_FEEDBACK: {
    url: '/dataCenter/channel-data/feedback',
    title: '渠道数据反馈'
  },

  //仪器
  INSTRUMENT_POLICY: {
    url: '/dataCenter/Instrument/management/policy',
    title: '仪器管理政策'
  },
  INSTRUMENT_LIST: {
    url: '/dataCenter/instrument/list',
    title: '仪器清单'
  },

  //合同
  DA_CONTRACT: {
    url: '/dataCenter/daAndLoa/DAContract',
    title: 'DA合同'
  },
  CIB_CONTRACT: {
    url: '/dataCenter/DA/LOA/contract',
    title: 'CIB合同'
  },

  //LOA信息
  LOA: {
    url: '/dataCenter/daAndLoa/loa',
    title: 'LOA信息'
  },

  //产品资质
  PRODUCT_REG_CERTIFICATE: {
    url: '/dataCenter/productCertificate',
    title: '产品注册证'
  },
  PRODUCT_RECALL: {
    url: '/dataCenter/tool/productRecalls',
    title: '产品召回'
  },
  PRODUCT_PHOTO_ATTACHMENT: {
    url: '/dataCenter/productDocument',
    title: '产品六面照'
  },

  //信息共享中心
  SHARE_IN_OUT: {
    url: '/dataCenter/tool/listedAndDelisting',
    title: '上市及退市'
  },
  RDSZ_SHARE_MATERIAL: {
    url: '/dataCenter/productQualification/rdsz',
    title: 'RDSZ上市资料'
  },
  SHARE_OTHER: {
    url: '/dataCenter/tool/otherConsultation',
    title: '其他资讯'
  },

  DEALER_USER: {
    url: '/userCenter',
    title: '账号管理'
  },
  STUDENT_MANAGEMENT: {
    url: '/userCenter',
    title: '学员信息管理'
  },

  // 全链路
  ALL_CHANNEL_DATA: {
    url: '/dataCenter/channel-data/chain',
    title: '全渠道链'
  },

  //客户服务
  AUTH_SERVICE_INSTRUMENT: {
    url: '/dataCenter/customerService/instrument/list',
    title: '授权服务仪器'
  },
  SHARED_DOCUMENT: {
    url: '/dataCenter/customerService/shareFile/list',
    title: '共享文档'
  },
  PERFORMANCE_ASSESSMENT: {
    url: '/dataCenter/customerService/score/list',
    title: 'CC绩效'
  },

  // MO收集
  MO: {
    url: '/dataCenter/performance/mo',
    title: 'MO收集'
  }
}
