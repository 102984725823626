<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :query-permission="['system:notice:query']"
      :is-need-operate="true"
      :initSearchParams="initSearchParams"
    >
      <template slot="table-btn" slot-scope="data">
        <span
          @click="doDelete(data.row)"
          v-show="currentYear == data.row.uploadYear && currentMonth == data.row.uploadMonth"
          >删除</span
        >
      </template>
    </form-table>
  </div>
</template>
<script>
import { tableOptions, queryUrl, searchFormData } from './config'
import { delMOHistory } from '@/api/file'
import { getDicts } from '@/api/dict/data'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      headerTitle: 'MO收集',
      queryUrl: queryUrl,
      tableOptions: tableOptions,
      searchFormData: searchFormData,
      initSearchParams: {
        orderByColumn: 'createTime',
        isAsc: 'desc'
      },
      currentYear: dayjs(new Date()).format('YYYY'),
      currentMonth: dayjs(new Date()).format('M')
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    async setOptions() {
      const productLineList = await getDicts('mo_product_line').then(res => {
        let list = []
        if (res.code === 200 && res.data.length > 0) {
          list = res.data
        }
        return list
      })
      this.tableOptions[0].rightTop.left.options = productLineList
    },
    doDelete(data) {
      let params = {
        id: data.id,
        fileId: data.fileId
      }
      this.$dialog
        .confirm({
          message: '是否确认删除该文件？'
        })
        .then(() => {
          delMOHistory(params).then(({ code, msg }) => {
            if (code != 200) {
              this.$toast.fail({
                message: msg,
                className: 'fail'
              })
              return
            }
            this.$toast.success('删除成功')
            this.$refs.baseForm.onRefresh()
          })
        })
        .catch(() => {})
    }
  }
}
</script>
<style scoped lang="less">
.item {
  color: #0b41cd;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: space-between;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.name {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
