import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const whiteList = ['/login', '/register', '/auth', '/maintenance', '/expire', '/maintenance.html']

// 配置项目路由
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PATH || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/home' // 重定向
    },

    {
      path: '/home', //总览
      name: 'Home',
      component: () => import('@/views/home/Home'),
      children: [
        // 二级路由 不能加'/' 加'/'表示一级路由
        {
          path: 'searchPopup',
          component: () => import('@/views/home/search/searchPopup.vue'),
          name: 'searchpopup',
          meta: {
            isShowTabbar: false
          }
        },
        // 联系人
        {
          path: 'contact',
          component: () => import('@/views/home/contact/index.vue'),
          name: 'homecontact',
          meta: {
            isShowTabbar: false
          }
        }
      ],
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/auth', //sso
      name: 'Auth',
      component: () => import('@/views/auth'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: () => import('@/views/login/maintenance'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/expire',
      name: 'Expire',
      component: () => import('@/views/login/expire'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/notice', //通知
      name: 'Notice',
      component: () => import('@/views/notice/index'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/notice/detail', //通知详情
      name: 'NoticeDetail',
      component: () => import('@/views/notice/detail'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/task', //任务
      name: 'Task',
      component: () => import('@/views/task/index'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/task/taskDetail', //自定义任务详情
      name: 'TaskDetail',
      component: () => import('@/views/task/formDetail.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/notice/file', //通知附件
      name: 'NoticeFile',
      component: () => import('@/views/notice/file'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/notice/calendar', //待办日历
      name: 'Calendar',
      component: () => import('@/views/notice/calendar'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/userCenter/dealerContactInfo/:dealerContactId',
      component: () => import('@/views/userCenter/dealerContact/info/index'),
      name: 'dealerContactInfo',
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/userCenter/dealerAccountInfo/:dealerId',
      component: () => import('@/views/userCenter/dealerAccount/info/index'),
      name: 'DealerAccountInfo',
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/userCenter', //用户中心
      name: 'UserCenter',
      component: () => import('@/views/userCenter/index'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/userCenter/detail', //学员详情
      name: 'StudentsDetail',
      component: () => import('@/views/userCenter/students/detail'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/dataCenter', //业务中心
      name: 'DataCenter',
      component: () => import('@/views/dataCenter/index'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/cib/kpiPolicy', //kpi政策
      name: 'KpiPolicy',
      component: () => import('@/views/dataCenter/kpi/policy'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/instrument/management/policy', //仪器政策
      name: 'Instrument',
      component: () => import('@/views/dataCenter/instrument/policy'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/instrument/list', //仪器清单
      name: 'InstrumentList',
      component: () => import('@/views/dataCenter/instrument/list'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/instrument/changeLog', //仪器清单变更记录
      name: 'InstrumentChangeLog',
      component: () => import('@/views/dataCenter/instrument/changeLog'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/dataCenter/instrument/appealDetail', //仪器清单申诉详情
      name: 'InstrumentAppeal',
      component: () => import('@/views/dataCenter/instrument/appealDetail.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/userCenter/students/changeLog', //学员变更记录
      name: 'studentsChangeLog',
      component: () => import('@/views/dataCenter/instrument/changeLog'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/dataCenter/cib/policy', //cib政策
      name: 'CibPolicy',
      component: () => import('@/views/dataCenter/cib/policy'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/cib/framework', //cib框架
      name: 'CibFramework',
      component: () => import('@/views/dataCenter/cib/framework'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/productCertificate', //产品注册证
      name: 'ProductCertificate',
      component: () => import('@/views/dataCenter/productCertificate/index'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/productQualification/rdsz', //RDSZ
      name: 'productQualificationRDSZ',
      component: () => import('@/views/dataCenter/productQualification/rdsz'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/cib/warning', //CIB 预警
      name: 'CIB_Warning',
      component: () => import('@/views/dataCenter/cib/warning'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/DA/LOA/contract', //CIB合同
      name: 'CIB_Contract',
      component: () => import('@/views/dataCenter/cib/contract'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/cib/confirmation/index', //CIB确认函
      name: 'CIB_Confirmation',
      component: () => import('@/views/dataCenter/cib/confirmation.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/cib/confirmation2', //CIB确认函
      name: 'CIB_Confirmation',
      component: () => import('@/views/dataCenter/cib/confirmation/index.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/cib/confirmation/dear/detail/:id', //CIB确认函-detail
      name: 'CIB_Confirmation_Detail',
      component: () => import('@/views/dataCenter/cib/confirmation/detail'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/performance/rcr', //RCR Scorecard
      name: 'RCR_Scorecard',
      component: () => import('@/views/dataCenter/performance/rcr/dataview'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/performance/sap', //SAP Scorecard
      name: 'SAP_Scorecard',
      component: () => import('@/views/dataCenter/performance/sap/dataview'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/kpi/kpiScore', //kpi--KPI考核结果的下达-列表
      name: 'kpiScore',
      component: () => import('@/views/dataCenter/kpi/performanceAppraisal/index'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/kpi/kpiScore/dear/detail/:id', //kpi--KPI考核结果的下达-详情
      name: 'kpiScoreDetail',
      component: () => import('@/views/dataCenter/kpi/performanceAppraisal/detail'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/channel-data/feedback', //渠道数据反馈
      name: 'Feedback',
      component: () => import('@/views/dataCenter/channelData/feedback.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/channel-data/inventory', //渠道数据承知
      name: 'Inventory',
      component: () => import('@/views/dataCenter/channelData/inventory.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/channel-data/chain', //全渠道链
      name: 'ChannelChain',
      component: () => import('@/views/dataCenter/channelData/chain.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/channel-data/chainDealer', //渠道链分销商列表
      name: 'ChainDealer',
      component: () => import('@/views/dataCenter/channelData/components/index.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/channel-data/chainEdit', //全渠道链编辑页
      name: 'ChannelChainEdit',
      component: () => import('@/views/dataCenter/channelData/components/detail.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/bgi/dealerBGI', //BGI
      name: 'DealerBGI',
      component: () => import('@/views/dataCenter/bgi/dealerBGI.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/bgi/dealerBGI-CA', //BGI-CA
      name: 'DealerBGI-CA',
      component: () => import('@/views/dataCenter/bgi/dealerBGI-CA.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/bgi/dealerBGI-GPO', //BGI-GPO
      name: 'DealerBGI-GPO',
      component: () => import('@/views/dataCenter/bgi/dealerBGI-GPO.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/daAndLoa/DAContract', //DA合同
      name: 'DAContract',
      component: () => import('@/views/dataCenter/daAndLoa/daContract.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/daAndLoa/loa', //LOA
      name: 'LOA',
      component: () => import('@/views/dataCenter/daAndLoa/loa.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/tool/productRecalls', //产品召回
      name: 'productRecalls',
      component: () => import('@/views/dataCenter/tool/productRecalls.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/tool/listedAndDelisting', //上市及退市
      name: 'listedAndDelisting',
      component: () => import('@/views/dataCenter/tool/listedAndDelisting.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/tool/otherConsultation', //其他资讯
      name: 'otherConsultation',
      component: () => import('@/views/dataCenter/tool/otherConsultation.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/customerService/instrument/list', //客户服务-授权服务仪器
      name: 'VadInstrumentList',
      component: () => import('@/views/dataCenter/customerService/instrument/index.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/customerService/instrument/changeLog', //客户服务-授权服务仪器变更记录
      name: 'VadInstrumentChangeLog',
      component: () => import('@/views/dataCenter/customerService/instrument/changeLog.vue'),
      meta: {
        isShowTabbar: false
      }
    },
    {
      path: '/dataCenter/customerService/shareFile/list', //客户服务-共享文档
      name: 'ShareDocument',
      component: () => import('@/views/dataCenter/customerService/document/index.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/customerService/score/list', //客户服务-绩效
      name: 'VadScore',
      component: () => import('@/views/dataCenter/customerService/score/index.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/customerService/score/dataDetail', //客户服务-绩效-详情
      name: 'VadScoreDetail',
      component: () => import('@/views/dataCenter/customerService/score/detail.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/productDocument', //产品六面照
      name: 'ProductDocument',
      component: () => import('@/views/dataCenter/productDocument/index.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/commonModule/list', //通用文件模块
      name: 'CommonModule',
      component: () => import('@/views/dataCenter/commonModule/index.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/dataCenter/performance/mo', //MO收集
      name: 'MO',
      component: () => import('@/views/dataCenter/performance/mo/index.vue'),
      meta: {
        isShowTabbar: true
      }
    },
    {
      path: '/search', // 全局搜索
      name: 'Search',
      component: () => import('@/views/search/index.vue'),
      meta: {
        isShowTabbar: true,
        keepAlive: true // 需要被缓存
      }
    },
    {
      path: '/login', // 登录
      name: 'Login',
      component: () => import('@/views/login/login.vue'),
      meta: {
        isShowTabbar: false
      }
    }
  ]
})

import { getToken, goSSO, getRefreshToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'
import store from '@/store'
// 路由前置守卫
router.beforeEach((to, from, next) => {
  console.log('router before')
  // 是否编辑模式
  if (store.state.mode.editing) {
    console.log('router before editing')
    window.$vm?.changeDealer(to.fullPath)
    return
  }
  if (to.fullPath.indexOf('errorCode') > -1 && to.fullPath.indexOf('403002') > -1) {
    // 过期激活链接跳转
    next({ path: '/expire', query: { code: '403002' } })
  } else if (to.fullPath.indexOf('errorCode') > -1 && to.fullPath.indexOf('403012') > -1) {
    // 错误页面跳转
    next({ path: '/expire', query: { code: '403012' } })
  } else {
    document.title = process.env.VUE_APP_TITLE
    if (getToken() && getRefreshToken()) {
      /* has token*/
      if (to.path === '/login') {
        next({ path: '/' })
      } else {
        if (store.getters.roles.length === 0) {
          isRelogin.show = true
          // 判断当前用户是否已拉取完user_info信息
          store
            .dispatch('GetInfo')
            .then(() => {
              isRelogin.show = false
              store.dispatch('getAllRoutes').then(() => {
                store.dispatch('GenerateRoutes').then(accessRoutes => {
                  // 根据roles权限生成可访问的路由表
                  router.addRoutes(accessRoutes) // 动态添加可访问路由表
                  next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
                })
              })
            })
            .catch(err => {
              console.log('token刷新失败，refreshToken已经过期，需要重新登录')
              Vue.prototype.$dialog
                .alert({
                  title: '系统提示',
                  message: `登录状态已过期，请重新登录`,
                  confirmButtonText: '重新登录',
                  showCancelButton: false
                })
                .then(() => {
                  store.dispatch('LogOut').then(() => {
                    goSSO()
                  })
                })
                .catch(() => {
                  store.dispatch('LogOut').then(() => {
                    goSSO()
                  })
                })
            })
        } else {
          next()
        }
      }
    } else {
      // 没有token
      if (whiteList.indexOf(to.path) !== -1) {
        // 在免登录白名单，直接进入
        next()
      } else {
        console.log('--!whiteList--')
        console.log(to.fullPath)
        if (to.fullPath.indexOf('maintenance.html') > -1) {
          next()
        } else {
          goSSO()
        }
      }
    }
  }
})

// 在vue-router在3.1.0版本之后，push和replace方法会返回一个promise对象，如果跳转到相同的路由，就报promise uncaught异常
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
