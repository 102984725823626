import request, { download, preview } from '@/utils/request'

// 查询列表
export function getFileList(query, url) {
  return request({
    url: url,
    method: 'get',
    params: query
  })
}

// 提交接口
export function saveInfo(data, url) {
  return request({
    url: url,
    method: 'post',
    data: data
  })
}

// 发送提醒
export function remindInfo(data, url) {
  return request({
    url: url,
    method: 'post',
    data: data
  })
}

// 上传文件
export function uploadFile(data) {
  return request({
    url: '/file/multi-upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 产品注册证删除
export function delProductCertificate(query) {
  return request({
    url: '/bff-dp-mobile/register-cert/delete',
    method: 'delete',
    params: query
  })
}

// 产品注册证上个有效期
export function lastValidPeriod(query) {
  return request({
    url: '/bff-dp-mobile/register-cert/last-valid-period',
    method: 'get',
    params: query
  })
}

// 产品六面照上一版本
export function lastAttachment(productAttachmentId) {
  return request({
    url: `/bff-dp-mobile/product-attachment/last-attachment/${productAttachmentId}`,
    method: 'get',
  })
}

// CIB预警删除
export function delCibHistory(query) {
  return request({
    url: '/bff-dp-mobile/cib-document/delete',
    method: 'delete',
    params: query
  })
}

// CIB预警历史版本
export function historyVersion(query) {
  return request({
    url: '/bff-dp-mobile/cib-document/history',
    method: 'get',
    params: query
  })
}

// SAPScorecard删除
export function delSAPHistory(query) {
  return request({
    url: '/bff-dp-mobile/score-card/sap/delete',
    method: 'delete',
    params: query
  })
}

// SAPScorecard历史版本
export function historyVersionSAP(query) {
  return request({
    url: '/bff-dp-mobile/score-card/sap/history',
    method: 'get',
    params: query
  })
}

// RCRScorecard删除
export function delRCRHistory(query) {
  return request({
    url: '/bff-dp-mobile/score-card/rcr/delete',
    method: 'delete',
    params: query
  })
}

// RCRScorecard历史版本
export function historyVersionRCR(query) {
  return request({
    url: '/bff-dp-mobile/score-card/rcr/history',
    method: 'get',
    params: query
  })
}

// 权限管理administrator-list
export function administratorList(id, url) {
  return request({
    url: `${url}?id=${id}`,
    method: 'get'
  })
}

export function downloadHistory(fileIds, waterMarkFlag) {
  download('/file/multi-download', {}, null, {
    method: 'POST',
    params: {
      watermarkFlag: waterMarkFlag,
      fileIds
    }
  })
}

//用户手册预览下载
export function previewUserManual(query, fileName) {
  preview('/file/downloadFromS3', {}, fileName, {
    method: 'get',
    params: {
      ...query
    }
  })
}

export function downloadTemplate(query, fileName) {
  download('/file/downloadFromS3', {}, fileName, {
    method: 'get',
    params: {
      ...query
    }
  })
}

//SAP Scorecard查询接口
export function getSAPDataview(query) {
  return request({
    url: '/bff-dp-mobile/score-card-data/sap/data',
    method: 'get',
    params: query
  })
}

//RCR Scorecard查询接口
export function getRCRDataview(query) {
  return request({
    url: '/bff-dp-mobile/score-card-data/rcr/data',
    method: 'get',
    params: query
  })
}

// 通用文件模块---查询模板数据
export function getCommonModuleData(params) {
  return request({
    url: '/bff-dp-mobile/customer-form-show/header/' + params,
    method: 'get'
  })
}

// MO删除
export function delMOHistory(params) {
  return request({
    url: '/bff-dp-mobile/mo-upload/history/delete/' + params.id,
    method: 'delete'
  })
}
