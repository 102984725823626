<template>
  <div class="performance-appraisal-detail">
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="head-arrow" @click="goToBack"><van-icon name="arrow-left" color="#0b41cd" /></van-col>
      <van-col class="head-title">{{ title }}</van-col>
      <van-col>
        <van-popover
          class="popover"
          placement="bottom-end"
          v-model="showPopover"
          trigger="click"
          :actions="btnData"
          @select="onSelect"
        >
          <template #reference>
            <van-icon name="ellipsis" />
          </template>
        </van-popover>
      </van-col>
    </van-row>
    <!-- <van-row>
      <van-col span="24">
        <div style="font-size: 12px; color: #0b41cd; font-weight: bold; padding: 5px 20px">
          备注：各项考核权重根据每家经销商的适用项做均分，运营KPI满分为100分
        </div>
      </van-col>
    </van-row> -->
    <div class="content" v-if="Object.keys(formatTableData).length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="initDetailData">
        <van-list v-model="loading" :finished="finished">
          <van-collapse v-model="activeName" class="collapse">
            <van-collapse-item v-for="(card, cardName) in formatTableData" :name="cardName" :key="cardName">
              <template #title>
                <div class="card-title">
                  <b>{{ cardName }} </b>
                </div>
              </template>
              <div class="collapse-content">
                <van-row class="content-item" v-for="(item, idx) in card" :key="cardName + idx">
                  <van-col span="24">
                    <div class="sub-title">
                      <span class="label">考核内容</span>
                      <span class="content" @click="openDetail(item)">
                        <span class="text"
                          ><span>{{ item[0].performanceItem }}</span></span
                        >
                        <van-icon name="more-o" class="icon-btn" />
                      </span>
                    </div>
                  </van-col>
                  <van-col span="24">
                    <div class="sub-title">
                      <span class="label">频率</span>
                      <span class="content">
                        <span class="text">{{ item[0].performanceFrequency || '-' }}</span>
                      </span>
                    </div>
                  </van-col>
                  <van-col span="24">
                    <div class="sub-title">
                      <span class="label">得分</span>
                      <span class="content">
                        <span class="text">{{ item[0].quarterScore || '-' }}</span>
                      </span>
                    </div>
                  </van-col>
                </van-row>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-else>
      <van-row v-if="searchFlag">
        <van-col :span="24" style="text-align: center">
          <div style="color: #aaaaaa; margin-top: 50px; font-size: 14px">暂无数据</div>
        </van-col>
      </van-row>
    </div>
    <div class="total-wrapper" v-if="Object.keys(formatTableData).length > 0">
      <div class="total-score" v-if="!loading && quarterTotalScore">
        <span class="score">{{ quarterTotalScore }}</span> 季度总得分
      </div>
      <div class="total-score fine-color" v-if="!loading && yearTotalScore">
        <span class="score">{{ yearTotalScore }}</span> 全年得分
      </div>
    </div>

    <van-dialog v-model="detailShow" class="detail-dialog">
      <van-row>
        <van-col span="24">
          <span class="margin-bottom">{{ detailInfo.performanceItem }}</span>
          <div v-for="(item, index) in detailsList" :key="index">
            <van-cell :title="item.label" :value="item.value" />
          </div>
        </van-col>
      </van-row>
    </van-dialog>
  </div>
</template>
<script>
import groupBy from 'lodash/groupBy'
import { getScoreDetail } from '@/api/dataCenter/customerService/score'
import { downloadHistory } from '@/api/file'
export default {
  name: 'VadScoreDetail',
  data() {
    return {
      btnData: [{ text: '下载RawData' }, { text: '下载' }],
      showPopover: false,
      activeName: [],
      refreshing: false,
      loading: false,
      finished: false,
      searchFlag: false,
      tableData: [],
      info: {},
      quarterTotalScore: null,
      yearTotalScore: null,
      detailShow: false,
      detailInfo: {},
      detailsList: [
        {
          prop: 'firstMonth',
          label: '',
          value: ''
        },
        {
          prop: 'secondMonth',
          label: '',
          value: ''
        },
        {
          prop: 'thirdMonth',
          label: '',
          value: ''
        },
        {
          prop: 'quarterRate',
          label: '',
          value: ''
        }
      ]
    }
  },
  computed: {
    detailID() {
      return this.$route.query.id
    },
    title() {
      return decodeURIComponent(this.$route.query.title)
    },
    formatTableData() {
      let obj = groupBy(this.tableData, 'performanceCategory')
      let newObj = {}
      for (let key in obj) {
        newObj[key] = groupBy(obj[key], 'performanceItem')
      }
      return newObj
    }
  },
  watch: {
    detailShow(val) {
      if (!val) {
        this.detailsList = [
          {
            prop: 'firstMonth',
            label: '',
            value: ''
          },
          {
            prop: 'secondMonth',
            label: '',
            value: ''
          },
          {
            prop: 'thirdMonth',
            label: '',
            value: ''
          },
          {
            prop: 'quarterRate',
            label: '',
            value: ''
          }
        ]
      }
    }
  },
  created() {
    this.initDetailData()
  },
  methods: {
    goToBack() {
      this.$router.go(-1)
    },
    openDetail(detail) {
      this.detailShow = true
      this.detailInfo = detail[0]
      this.detailsList.map(item => {
        if (item.prop === 'firstMonth') {
          item.label = this.info.quarterMonthTitleList?.length > 0 ? this.info.quarterMonthTitleList[0] : ''
          item.value = this.detailInfo['firstMonth'] || '-'
        } else if (item.prop === 'secondMonth') {
          item.label = this.info.quarterMonthTitleList?.length > 0 ? this.info.quarterMonthTitleList[1] : ''
          item.value = this.detailInfo['secondMonth'] || '-'
        } else if (item.prop === 'thirdMonth') {
          item.label = this.info.quarterMonthTitleList?.length > 0 ? this.info.quarterMonthTitleList[2] : ''
          item.value = this.detailInfo['thirdMonth'] || '-'
        } else if (item.prop === 'quarterRate') {
          item.label = this.info.performanceYear + this.info.performancePeriod
          item.value = this.detailInfo['quarterRate'] || '-'
        }
      })
    },
    initDetailData() {
      this.refreshing = true
      this.loading = true
      return this.queryDetail(this.detailID).then(data => {
        this.loading = false
        this.finished = true
        this.tableData = data
        this.refreshing = false
      })
    },
    queryDetail(params) {
      return getScoreDetail(params).then(res => {
        this.searchFlag = true
        if (res.data) {
          this.quarterTotalScore = res.data.quarterTotalScore
          this.yearTotalScore = res.data.yearTotalScore
          this.info = res.data
          return res.data.detailDealerResultDtoList || []
        }
        this.info = {}
        return []
      })
    },
    onDownload() {
      if (this.info.sumFlag === 'N') {
        this.$toast.fail('还未上传相关数据，无法下载！')
        return false
      }
      this.download(
        '/bff-dp-mobile/performance-result/export-pdf/' + this.info.performanceDealerId,
        {},
        `${this.title}.pdf`,
        {
          method: 'get'
        }
      )
    },
    onDownloadRawData() {
      if (this.info.rawDataFlag === 'N') {
        this.$toast.fail('还未上传相关RawData数据，无法下载！')
        return false
      }
      if (this.info?.oldData == '1') {
        let fileIds = []
        this.info.dealerRawDataFileList.forEach(item => {
          fileIds.push(item.fileId)
        })
        downloadHistory(fileIds.join(','), true)
      } else {
        let param = {
          dealerId: this.info.dealerId,
          year: this.info.performanceYear,
          quarter: this.info.performancePeriod
        }
        this.download('/bff-dp-mobile/performance-raw-data/download', {}, null, {
          method: 'POST',
          params: {
            ...param
          }
        })
      }
    },
    onSelect(obj) {
      if (obj.text === '下载') {
        this.onDownload()
      } else {
        this.onDownloadRawData()
      }
    }
  }
}
</script>
<style scoped lang="less">
.performance-appraisal-detail {
  background: linear-gradient(296.57deg, #d9ebfb 0%, #d9ebfb 5.27%, #f2f2f7 35.59%, #fef9f7 65.39%, #fffefe 83.33%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100vh);
  .detail-tabs {
    ::v-deep .van-tab {
      flex: unset;
    }
  }
  .performance-appraisal-nav {
    ::v-deep .van-nav-bar {
      background-color: #fff;
      .van-nav-bar__content {
        .van-nav-bar__left {
          .van-icon {
            color: rgba(38, 38, 38, 1);
          }
        }
      }
    }
  }
  .right-btn-link {
    color: rgba(11, 65, 205, 1);
    border-right: 1px solid rgba(206, 217, 245, 1);
    margin: 10px 0;
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
  .content {
    // flex: 1;
    //overflow-y: auto;
    padding: 0 16px;
  }
  .total-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .total-score {
    font-size: 12px;
    display: inline-flex;
    align-items: flex-end;
    color: rgba(9, 52, 164, 1);
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-right: 16px;
    .score {
      font-size: 32px;
      font-weight: 600;
    }
  }
  .fine-color {
    color: #ff494b;
  }
  .collapse {
    padding-top: 12px;
    .noBG {
      ::v-deep .van-collapse-item--border {
        box-shadow: none;
        margin-bottom: 0;
      }
      ::v-deep .van-cell .van-cell__title {
        flex-grow: 2;
      }
      ::v-deep .sub-title .content {
        text-align: left !important;
      }
    }
    .van-collapse-item {
      align-items: center;
      margin-bottom: 12px;
      border-radius: 12px;
      box-shadow: 0 8px 12px #ebedf0;
      overflow: hidden;
      ::v-deep .van-cell {
        align-items: center;
      }
      ::v-deep .van-collapse-item__title {
        background: linear-gradient(
          296.57deg,
          #d9ebfb 0%,
          #d9ebfb 5.27%,
          #f2f2f7 35.59%,
          #fef9f7 65.39%,
          #fffefe 83.33%
        );
      }
      .collapse-content {
        .content-item {
          padding-bottom: 12px;
          &:not(:last-child) {
            border-bottom: 1px solid rgba(235, 237, 240, 1);
            margin-bottom: 12px;
          }
          .sub-title {
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            font-size: 14px;
            margin-bottom: 10px;
            color: rgba(20, 20, 20, 1);
            .label {
              padding-right: 10px;
              &.gray {
                color: #8c8c8c;
                font-size: 12px;
              }
            }
            .content {
              flex: 1;
              display: flex;
              padding-right: 0;
              align-items: flex-start;
              text-align: right;
              > .text {
                flex: 1;
                color: rgba(9, 52, 164, 1);
                display: flex;
                justify-content: flex-end;
              }
            }
          }
          .assessment-item {
            .van-icon.van-icon-info-o {
              margin-left: 5px;
            }
          }
        }
        ::v-deep .van-cell {
          align-items: normal;
          font-size: 12px;
          line-height: 14px;
          padding: 10px 0;
          background: none !important;
          &.high-light {
            .van-cell__value {
              font-size: 16px;
              color: rgba(9, 52, 164, 1);
            }
          }
        }
      }
    }
    .card-title {
      font-size: 16px;
      b {
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }
}
.icon-btn {
  color: rgb(11, 65, 205);
  margin-left: 5px;
  line-height: 23px;
}
.detail-dialog {
  padding-top: 20px;
  .van-row {
    width: 85%;
    margin: 0 auto 10px;
    border-bottom: 1px solid rgba(235, 237, 240, 1);
    &:last-child {
      margin-bottom: 0;
    }
    .van-cell {
      padding: 0 10px;
    }
  }
  .sub-title {
    display: flex;
    .label {
      flex-shrink: 0;
    }
  }

  ::v-deep {
    .van-dialog__content {
      max-height: 70vh;
      overflow: scroll;
    }
    .van-cell--clickable {
      padding: 0px 10px;
    }
    .van-cell__right-icon {
      display: none;
    }
    .appeal {
      background: #ee8441;
      border-radius: 8px;
      color: #fff;
      display: inline-block;
      margin-left: 5px;
      padding: 0px 5px;
      font-size: 12px;
    }
    .van-cell {
      padding: 4px !important;
    }
  }
}
.font-size {
  font-size: 14px;
}
.appeal {
  background: #ee8441;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  margin-left: 5px;
  padding: 0px 5px;
  font-size: 12px;
}
.margin-bottom {
  margin-bottom: 10px;
  display: block;
}

.header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;

  .popover {
    color: #0b41cd;
    font-size: 16px;
    position: absolute;
    height: 52px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  .head-arrow {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 16px;
  }

  .head-title {
    font-weight: 600;
  }

  .head-btn {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: #0b41cd;
  }
}
</style>
