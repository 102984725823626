export const queryUrl = '/bff-dp-mobile/mo-upload/history/list'

export const searchFormData = [
  { props: 'fileName', label: '文件名', type: 'input' },
  {
    props: 'uploadYear',
    label: '上传年份',
    type: 'date',
    valueFormat: 'yyyy',
    dateFormat: 'yyyy',
    dateType: 'year'
  },
  {
    props: 'uploadMonth',
    label: '上传月份',
    type: 'DictSelect',
    dictCode: 'sys_month',
    multiple: false
  },
  {
    props: 'bl',
    label: '产品线',
    type: 'DictSelect',
    dictCode: 'mo_product_line',
    multiple: false
  }
]

export const tableOptions = [
  {
    prop: 'fileName',
    label: '文件名',
    type: 'fileName',
    rightTop: {
      left: {
        prop: 'bl',
        label: '产品线',
        dictCode: 'mo_product_line',
        options: []
      }
    }
  },
  {
    prop: 'uploadYear',
    label: '上传年份'
  },
  {
    prop: 'uploadMonth',
    dictCode: 'sys_month',
    label: '上传月份'
  },
  {
    prop: 'createTime',
    label: '上传时间'
  }
]
