<template>
  <van-row class="main-container">
    <van-row class="header" :class="theme.headerClass">
      <van-row class="title">
        <van-col>SAP</van-col>
        <van-col class="date">{{ sapDate }}</van-col>
      </van-row>
      <van-row class="tabs">
        <van-col
          class="item"
          v-for="(item, index) in scorecardSAP"
          :key="index"
          :class="currentProduct.productLine === item.productLine ? 'active' : ''"
          @click="changeShowProduct(item)"
          >{{ item.productLine | dictLabelByValue(productLineOptions) }}</van-col
        >
      </van-row>
    </van-row>
    <div v-show="showType === 'mtd'">
      <van-col :span="24" style="padding: 0px 5px">
        <div class="chart" id="chartSAP"></div>
      </van-col>
    </div>
    <div v-show="showType === 'ytd'" @click="goToDetail">
      <van-row class="result">
        <van-col class="item">
          <van-col class="title">实际销量(单位:k)</van-col>
          <van-col class="price" v-if="ytdResult.actual || ytdResult.actual === 0">{{
            ytdResult.actual | numberFormat
          }}</van-col>
          <van-col class="price" v-else>-</van-col>
        </van-col>
        <van-col class="item">
          <van-col class="title">目标销量(单位:k)</van-col>
          <van-col class="price" v-if="ytdResult.target || ytdResult.target === 0">{{
            ytdResult.target | numberFormat
          }}</van-col>
          <van-col class="price" v-else>-</van-col>
        </van-col>
      </van-row>
      <van-row class="result">
        <van-col class="item">
          <van-col class="title">达成率</van-col>
          <van-col class="price">{{ ytdResult.achievementRate | formatRate }}</van-col>
        </van-col>
        <van-col class="item">
          <van-col class="title">增长率</van-col>
          <van-col class="price">{{ ytdResult.growthRate | formatRate }}</van-col>
        </van-col>
      </van-row>
    </div>
    <!--    <van-row class="unit">单位:k</van-row>-->
    <!--    <van-row class="map">-->
    <!--      <van-col class="item" v-if="option.series[0].data[0].value > 0">-->
    <!--        <van-row class="color-a"></van-row>-->
    <!--        <van-row class="name">LS</van-row>-->
    <!--      </van-col>-->
    <!--      <van-col class="item" v-if="option.series[0].data[1].value > 0">-->
    <!--        <van-row class="color-b"></van-row>-->
    <!--        <van-row class="name">TD</van-row>-->
    <!--      </van-col>-->
    <!--      <van-col class="item" v-if="option.series[0].data[2].value > 0">-->
    <!--        <van-row class="color-c"></van-row>-->
    <!--        <van-row class="name">CPS&MD</van-row>-->
    <!--      </van-col>-->
    <!--    </van-row>-->
    <van-row class="action">
      <van-row class="btn radius-left" :class="showType === 'mtd' ? 'active' : ''" @click.stop="changeShowType('mtd')"
        >MTD</van-row
      >
      <van-row class="btn radius-right" :class="showType === 'ytd' ? 'active' : ''" @click.stop="changeShowType('ytd')"
        >YTD</van-row
      >
    </van-row>
    <!--    <div class="unit" v-show="showType === 'ytd'">单位:k</div>-->
    <div class="bg-color" />
    <van-image class="bg-img" :src="require('@/assets/images/home-jixiao.png')" />
  </van-row>
</template>
<script>
import * as echart from 'echarts'
import { cloneDeep } from 'lodash'
import { getScorecardSAP, getScorecardResult } from '@/api/home'
import { formatNumberWithCommas } from '@/utils/common'
import { getDicts } from '@/api/dict/data'

const THEME_CONFIG = {
  bgClass: 'bg-blue',
  headerClass: 'header-blue'
}
export default {
  name: 'ChartSAP',
  props: {
    index: {
      type: String
    }
  },
  data() {
    return {
      theme: THEME_CONFIG,
      showType: 'mtd',
      myChart: null,
      scorecardResultSAP: {},
      scorecardSAP: [],
      sapDate: '',
      showCurrentTime: false,
      totalSAP: null,
      currentProduct: {},
      productLineOptions: [],
      ytdResult: {},
      option: {
        grid: {
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px'
        },
        series: [
          {
            color: ['#0B41CD', '#C2D3FE', '#7290DF'],
            name: 'Access From',
            type: 'pie',
            hoverAnimation: false,
            legendHoverLink: false, //是否启用图例 hover 时的联动高亮。
            radius: ['70%', '100%'],
            // adjust the start angle
            startAngle: 180,
            label: {
              show: true,
              formatter: params => {
                return params.name + '：' + formatNumberWithCommas(params.value)
              },
              overflow: 'break',
              fontSize: 11,
              fontFamily: 'RocheSans',
              lineHeight: 14
            },
            labelLine: {
              length: 2,
              length2: 2,
              show: true
            },
            labelLayout(params) {
              return {
                moveOverlap: 'shiftY',
                height: 20,
                y: params.rect.y > 50 ? params.rect.y - 30 : params.rect.y + 20
              }
            },
            data: [
              { value: 0 },
              { value: 0 },
              { value: 0 },
              {
                // make an record to fill the bottom 50%
                value: 0,
                itemStyle: {
                  // stop the chart from rendering this piece
                  color: 'none',
                  decal: {
                    symbol: 'none'
                  }
                },
                label: {
                  show: false
                }
              }
            ]
          }
        ]
      }
    }
  },
  created() {
    // this.getScorecardResult()
    this.getScorecardSAP()
    this.getDicts()
  },
  mounted() {
    this.myChart = echart.init(document.getElementById('chartSAP'))
  },
  filters: {
    formatRate(val) {
      return val ? (val * 100).toFixed(0) + '%' : '-'
    },
    formatRate2(val) {
      return val ? (val * 100).toFixed(2) + '%' : '-'
    }
  },
  methods: {
    getDicts() {
      getDicts('sys_product_line').then(res => {
        this.productLineOptions = res.data
      })
    },
    getScorecardResult() {
      let params = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      }
      getScorecardResult(params)
        .then(res => {
          this.scorecardResult = [...res.data.sap]
          this.$nextTick(() => {
            this.renderChart()
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getScorecardSAP() {
      let params = {}
      getScorecardSAP(params)
        .then(res => {
          this.scorecardResultSAP = res.data
          this.scorecardSAP = this.scorecardResultSAP[this.showType]
          this.$nextTick(() => {
            if (this.scorecardSAP.length > 0) {
              this.currentProduct = this.scorecardSAP[0]
              this.renderChartNew(this.currentProduct.productLine)
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    unique(arr) {
      const res = new Map()
      return arr.filter(arr => !res.has(arr.productLine) && res.set(arr.productLine, 1))
    },
    changeShowType(type) {
      if (type !== this.showType) this.showType = type
      this.scorecardSAP = this.scorecardResultSAP[this.showType]
      if (this.showType === 'mtd') {
        this.renderChartNew(this.currentProduct.productLine)
      } else {
        this.scorecardSAP.forEach(item => {
          if (item.productLine === this.currentProduct.productLine) {
            this.ytdResult = { ...item }
            this.sapDate = item.year + '年 ' + item.month + '月'
          }
        })
      }
    },
    changeShowProduct(currentProduct) {
      this.currentProduct = currentProduct
      if (this.showType === 'mtd') {
        this.renderChartNew(this.currentProduct.productLine)
      } else {
        this.scorecardSAP.forEach(item => {
          if (item.productLine === this.currentProduct.productLine) {
            this.ytdResult = { ...item }
            this.sapDate = item.year + '年 ' + item.month + '月'
          }
        })
      }
    },
    renderChart() {
      const _option = cloneDeep(this.option)
      let _ls = 0 // LS
      let _td = 0 // TD
      let _cps_md = 0 // CPS&MD
      this.scorecardResult.forEach(item => {
        if (item.department === 'LS' && item.viewType === this.showType) {
          _ls = item.value
        } else if (item.department === 'TD' && item.viewType === this.showType) {
          _td = item.value
        } else if (item.department === 'CPS_MD' && item.viewType === this.showType) {
          _cps_md = item.value
        }
        this.totalSAP += item.value
      })
      let total = Math.abs(_ls) + Math.abs(_td) + Math.abs(_cps_md)
      let totalOld = _ls + _td + _cps_md
      this.showCurrentTime = true
      if (total === 0) {
        _ls = null
        _td = null
        _cps_md = null
        total = ''
        this.showCurrentTime = false
      }
      _option.series[0].data = [
        {
          value: _ls ? Math.abs(_ls) : _ls,
          valueOld: _ls,
          name: 'LS',
          label: {
            show: Math.abs(_ls) > 0,
            formatter: function (arg) {
              if (arg.value == null || arg.value == 0) {
                return ''
              } else {
                return arg.name + '：' + formatNumberWithCommas(arg.data.valueOld)
              }
            }
          }
        },
        {
          value: _td ? Math.abs(_td) : _td,
          valueOld: _td,
          name: 'TD',
          label: {
            show: Math.abs(_td) > 0,
            formatter: function (arg) {
              if (arg.value == null || arg.value == 0) {
                return ''
              } else {
                return arg.name + '：' + formatNumberWithCommas(arg.data.valueOld)
              }
            }
          }
        },
        {
          value: _cps_md ? Math.abs(_cps_md) : _cps_md,
          valueOld: _cps_md,
          name: 'CPS&MD',
          label: {
            show: Math.abs(_cps_md) > 0,
            formatter: function (arg) {
              if (arg.value == null || arg.value == 0) {
                return ''
              } else {
                return arg.name + '：' + formatNumberWithCommas(arg.data.valueOld)
              }
            }
          }
        },
        {
          // make an record to fill the bottom 50%
          value: total,
          valueOld: totalOld,
          label: {
            show: false
          },
          itemStyle: {
            // stop the chart from rendering this piece
            color: 'none',
            decal: {
              symbol: 'none'
            }
          }
        }
      ]
      _option.title = {
        text: total > 0 ? formatNumberWithCommas(totalOld) : '',
        textStyle: {
          fontSize: 24,
          fontWeight: 'normal',
          fontFamily: 'RocheSans'
        },
        left: 'center',
        top: 60
      }
      this.option = _option
      this.myChart.clear()
      this.myChart.setOption(this.option)
    },
    renderChartNew(productLine) {
      // let myChartInfo = echart.init(document.getElementById('chartSAP'))
      let scoreCardData = {}
      this.scorecardSAP.forEach(item => {
        if (item.productLine === productLine) {
          scoreCardData = { ...item }
          this.sapDate = item.year + '年 ' + item.month + '月'
        }
      })
      const achievementRateArr = scoreCardData.achievementRate.map(item => {
        return item && item !== '' ? (item * 100).toFixed(0) : item
      })
      const growthRateArr = scoreCardData.growthRate.map(item => {
        return item && item !== '' ? (item * 100).toFixed(0) : item
      })
      const actualArr = scoreCardData.actual.map(item => {
        return item
      })
      const targetArr = scoreCardData.target.map(item => {
        return item
      })
      let moArr = []
      if (scoreCardData.mo) {
        moArr = scoreCardData.mo.map(item => {
          return item
        })
      }
      if (moArr.length > 0) {
        actualArr.forEach((item, index) => {
          if (item > 0) {
            moArr[index] = 0
          }
        })
      }
      scoreCardData.achievementRate = achievementRateArr
      scoreCardData.growthRate = growthRateArr
      scoreCardData.actual = actualArr
      scoreCardData.target = targetArr
      scoreCardData.moData = moArr
      const sort1 = [...scoreCardData.actual, ...scoreCardData.target, ...scoreCardData.moData]
      sort1.sort((a, b) => a - b)
      const sort2 = [...scoreCardData.achievementRate, ...scoreCardData.growthRate]
      sort2.sort((a, b) => a - b)
      console.log(JSON.stringify(sort1))
      console.log(JSON.stringify(sort2))
      const maxNum = this.formatLabelNum(sort1[sort1.length - 1])
      const minNum = sort1[0] < 0 ? this.formatLabelNum(sort1[0]) : 0
      const maxNum2 = this.formatLabelNum(sort2[sort2.length - 1])
      const minNum2 = sort2[0] < 0 ? this.formatLabelNum(sort2[0]) : 0
      console.log(maxNum)
      console.log(minNum)
      console.log(((maxNum - minNum) / 5).toFixed(0))
      console.log(maxNum2)
      console.log(minNum2)
      console.log(((maxNum2 - minNum2) / 5).toFixed(0))
      const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
      // console.log(this.formatBar(scoreCardData.moData, 'MO'))
      // console.log(this.formatBar(scoreCardData.actual, '实际销量'))
      // console.log(this.formatBar(scoreCardData.target, '目标销量'))
      let _option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
              width: 0
            },
            label: {
              show: false
            }
          },
          formatter: function (params) {
            let tooltipObj = {
              month: '',
              MO: {
                value: '-',
                marker:
                  '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#aaaaaa;"></span>'
              },
              实际销量: {
                value: '-',
                marker:
                  '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#0B41CD;"></span>'
              },
              目标销量: {
                value: '-',
                marker:
                  '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#C2D3FE;"></span>'
              },
              增长率: {
                value: undefined,
                marker:
                  '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#7290DF;"></span>'
              },
              达成率: {
                value: undefined,
                marker:
                  '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#00C34E;"></span>'
              }
            }
            let tooltipHtml = ''
            params.forEach(function (param) {
              if (param.value !== undefined && param.value !== '') {
                tooltipObj.month = param.name
                if (param.seriesName === '增长率' || param.seriesName === '达成率') {
                  tooltipObj[param.seriesName].value = param.value + '%'
                } else {
                  tooltipObj[param.seriesName].value = param.value
                }
              }
            })
            Object.keys(tooltipObj).forEach(key => {
              if (key === 'month') {
                tooltipHtml += tooltipObj[key] + '<br/>'
              } else {
                if (tooltipObj[key].value !== undefined && tooltipObj[key].value !== '-') {
                  tooltipHtml +=
                    tooltipObj[key].marker +
                    '<span>' +
                    key +
                    ': ' +
                    '</span>' +
                    '<span style="font-weight: bold">' +
                    tooltipObj[key].value +
                    '</span>' +
                    '<br/>'
                } else if (tooltipObj[key].value === '-' && key !== 'MO') {
                  tooltipHtml +=
                    tooltipObj[key].marker +
                    '<span>' +
                    key +
                    ': ' +
                    '</span>' +
                    '<span style="font-weight: bold">' +
                    tooltipObj[key].value +
                    '</span>' +
                    '<br/>'
                }
              }
            })
            return tooltipHtml
          }
        },
        legend: {
          itemHeight: 8,
          itemWidth: 8, // 修改icon图形大小
          itemGap: 24, // 修改间距
          textStyle: {
            fontSize: 12,
            color: '#8C8C8C',
            padding: [0, 0, 0, 0] // 修改文字和图标距离
          },
          data: ['MO', '实际销量', '目标销量', '增长率', '达成率']
        },
        // xAxis: [
        //   {
        //     type: 'category',
        //     data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        //     axisPointer: {
        //       type: 'shadow'
        //     },
        //     axisLine: {
        //       onZero: false
        //     }
        //   }
        // ],
        xAxis: months.map((item, index) => {
          const data = Array(months.length).fill('')
          data[index] = item
          return {
            type: 'category',
            position: 'bottom',
            data: data,
            axisPointer: {
              type: 'shadow'
            },
            axisLine: {
              onZero: false
            }
          }
        }),
        yAxis: [
          {
            type: 'value',
            // name: '销量(单位:k)',
            min: minNum,
            max: maxNum,
            // interval: ((maxNum - minNum) / 5).toFixed(0),
            alignTicks: true,
            splitNumber: 5,
            axisLabel: {
              fontSize: 10,
              formatter: '{value}',
              show: false
            },
            splitLine: {
              show: true
            }
          },
          {
            type: 'value',
            // name: '百分比',
            min: minNum2,
            max: maxNum2,
            // interval: ((maxNum2 - minNum2) / 5).toFixed(0),
            alignTicks: true,
            splitNumber: 5,
            axisLabel: {
              fontSize: 10,
              formatter: '{value}%',
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          ...this.formatBar(scoreCardData.moData, 'MO'),
          ...this.formatBar(scoreCardData.actual, '实际销量'),
          ...this.formatBar(scoreCardData.target, '目标销量'),
          // {
          //   name: 'MO',
          //   type: 'bar',
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value ? value : '-'
          //     }
          //   },
          //   itemStyle: {
          //     color: '#aaaaaa'
          //   },
          //   data: scoreCardData.moData
          // },
          // {
          //   name: '实际销量',
          //   type: 'bar',
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value ? value : '-'
          //     }
          //   },
          //   itemStyle: {
          //     color: '#0B41CD'
          //   },
          //   data: scoreCardData.actual
          // },
          // {
          //   name: '目标销量',
          //   type: 'bar',
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value ? value : '-'
          //     }
          //   },
          //   itemStyle: {
          //     color: '#C2D3FE'
          //   },
          //   data: scoreCardData.target
          // },
          {
            name: '增长率',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value ? value + '%' : '-'
              }
            },
            itemStyle: {
              color: '#7290DF'
            },
            data: scoreCardData.growthRate
          },
          {
            name: '达成率',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value ? value + '%' : '-'
              }
            },
            itemStyle: {
              color: '#00C34E'
            },
            data: scoreCardData.achievementRate
          }
        ]
      }
      this.myChart.clear()
      this.myChart.setOption(_option)
      this.myChart.on('legendselectchanged', function () {
        // 阻止冒泡
        event.stopPropagation()
        event.cancelBubble = true
      })
    },
    //为了处理图表中数据为空的柱子显示问题，对x轴数据进行格式化操作
    formatBar(arr, name) {
      const bar = []
      arr.forEach((item, index) => {
        const data = []
        for (let i = 0; i < index; i++) {
          data.push('')
        }
        if (item) {
          data.push(item)
          bar.push({
            name,
            type: 'bar',
            xAxisIndex: index,
            barWidth: 10,
            tooltip: {
              valueFormatter: function (value) {
                return value ? value : '-'
              }
            },
            itemStyle: {
              color: name === 'MO' ? '#aaaaaa' : name === '实际销量' ? '#0B41CD' : '#C2D3FE'
            },
            data
          })
        }
      })
      return bar
    },
    goToDetail() {
      this.$router.push('/dataCenter/performance/sap')
    },
    formatLabelNum(val) {
      if (val >= 0) {
        if (val > 10000) {
          return Math.ceil(val / 10000) * 10000
        } else if (val > 1000 && val <= 10000) {
          return Math.ceil(val / 1000) * 1000
        } else if (val > 100 && val <= 1000) {
          return Math.ceil(val / 100) * 100
        } else {
          return 100
        }
      } else {
        if (val < -10000) {
          return Math.ceil(val / 10000) * 10000
        } else if (val < -1000 && val >= -10000) {
          return Math.ceil(val / 1000) * 1000
        } else if (val < -100 && val >= -1000) {
          return Math.ceil(val / 100) * 100
        } else {
          return -100
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .header {
    height: 75px;
    box-sizing: border-box;
    border-radius: 0 0 100px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    padding: 15px 16px;
    position: relative;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        color: #595959;
        font-size: 12px;
        font-weight: 400;
        margin-left: auto;
      }
    }
    .tabs {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      line-height: 1;
      max-width: 250px;
      overflow-x: auto;
      .item {
        padding: 0 12px;
        color: #595959;
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;
        border-radius: 6px 6px 0 0;
        background: #edf2ff;
        min-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
      .active {
        background: linear-gradient(0deg, #fff 0%, #fff 100%), #edf2ff;
        color: #0b41cd;
        padding: 0 12px;
        min-height: 30px;
      }
    }
  }
}
.chart-container {
  width: 100%;
  //max-height: 95px;
  margin: 10px 0px 10px -5px;
}
.chart {
  width: 100%;
  height: 250px;
  margin: 10px 0px;
}
.unit {
  font-size: 12px;
  font-weight: 500;
  color: #8c8c8c;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.map {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #8c8c8c;
  font-weight: 400;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
  .color-a {
    width: 8px;
    height: 8px;
    background: #0b41cd;
    margin-right: 4px;
    border-radius: 1px;
  }
  .color-b {
    width: 8px;
    height: 8px;
    background: #c2d3fe;
    margin-right: 4px;
    border-radius: 1px;
  }
  .color-c {
    width: 8px;
    height: 8px;
    background: #7290df;
    margin-right: 4px;
    border-radius: 1px;
  }
}
.action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #e4e7ed;
  margin: 5px auto;
  .btn {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #606266;
    background: #fff;
  }
  .active {
    background: #0b41cd;
    color: #fff;
  }
  .radius-left {
    border-radius: 6px 0 0 6px;
  }
  .radius-right {
    border-radius: 0 6px 6px 0;
  }
}
.bg-img {
  width: 75px;
  height: 75px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bg-color {
  background: linear-gradient(to top, rgba(233, 242, 253, 1) 15%, rgba(206, 228, 255, 0.5) 50%, rgba(255, 255, 255, 1));
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
}
.empty-container {
  transform: translateY(-55px);
}

.header-blue {
  background: linear-gradient(90deg, #ced9f5 0%, rgba(231, 239, 253, 0.26) 100%);
  border-bottom: 1px solid #ced9f5;
}

.bg-blue {
  background: linear-gradient(180deg, rgba(233, 242, 253, 0.15) 0%, rgba(206, 228, 255, 0.5) 100%), #fff;
}
.result {
  height: 90px;
  padding-left: 16px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  line-height: 1;
  .item {
    display: flex;
    flex-direction: column;
    .title {
      color: #8c8c8c;
      font-size: 12px;
      font-weight: 400;
    }
    .price {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin-top: 16px;
      min-height: 22px;
    }
    .price-mini {
      font-size: 14px;
      min-height: 22px;
    }
  }
}
</style>
